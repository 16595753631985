import React, { Fragment, cloneElement } from 'react'
import { connect } from 'react-redux'
import { flowRight as compose } from 'lodash'
import { push } from 'react-router-redux'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  SelectInput,
  FunctionField,
  SearchInput,
  AutocompleteInput,
  ReferenceInput,
  Filter,
  downloadCSV,
  useListContext,
  TopToolbar,
  CreateButton,
  sanitizeListRestProps,
  TextInput,
  Button,
} from 'react-admin'
import { Route } from 'react-router-dom'
import { Drawer, Grid, withStyles } from '@material-ui/core'
import { TransactionDetail } from './TransactionDetail'
import jsonExport from 'jsonexport/dist'
import moment from 'moment'
import { DateRange } from './DateRange'
import ExportButton from '../shared-components/export-button'
import { paymentChannelDesc } from '../constants/payment-channel-desc'
import { useHistory } from 'react-router-dom'
import { useCustomerId } from '../zustand/customerIdStore'
import VisibilityIcon from '@material-ui/icons/Visibility'
import IconButton from '@material-ui/core/IconButton'
import FilterListIcon from '@material-ui/icons/FilterList'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import Tooltip from '@material-ui/core/Tooltip'

const styles = {
  drawerContent: {
    width: '700px',
  },
  lossProfit: {
    color: 'red',
  },
  tranID: {
    color: 'blue',
  },
  smallIcon: {
    width: '17px',
    height: '17px',
  },
}

const rowStyleByStatus = (record) => {
  function getColor(record) {
    if (record.latest_status === 'success') {
      return '#dcf8c6'
    }

    if (record.latest_status === 'failed') {
      return '#ffe4e4'
    }
    return 'transaparent'
  }

  return {
    backgroundColor: getColor(record),
  }
}

const exporter = async (records) => {
  const mappedData = records.map((record) => {
    return Object.assign({
      Created: moment(record.created_at).format('DD-MM-YYYY HH:mm:ss Z'),
      'Order ID': record.order_id,
      Customer: record.customers.name,
      'FB ID': record.customers.channel_id,
      'First Interaction': moment(record.customers.created_at).format('DD-MM-YYYY HH:mm:ss Z'),
      Product: record.products.description,
      Target: record.target,
      'Payment ID': record.payment_id,
      Point: paymentChannelDesc[record.pg_channel],
      Cost: record.cost,
      Discount: record.discount,
      Fee: record.pg_fee,
      Profit: record.profit,
      Status: record.latest_status,
      Price: record.sell_price,
    })
  })

  jsonExport(
    mappedData,
    {
      headers: [
        'Created',
        'Order ID',
        'Customer',
        'First Interaction',
        'FB ID',
        'Product',
        'Target',
        'Payment ID',
        'Point',
        'Price',
        'Cost',
        'Discount',
        'Fee',
        'Profit',
        'Status',
      ],
    },
    (err, csv) => {
      downloadCSV(csv, 'transactions')
    }
  )
}

const TransactionsFilter = (props) => {
  return (
    <Filter {...props}>
      <DateRange alwaysOn />
      <TextInput source="order_id" alwaysOn label="Search By Order ID" />
      <TextInput source="customer_id" label="cust UUID" />
      <TextInput source="promotion_code" label="Promotion code" />
      <TextInput source="tran_id" label="Tran ID" />
      <SearchInput source="target" />
      <ReferenceInput source="user_id" reference="users">
        <SelectInput source="name" />
      </ReferenceInput>
      <ReferenceInput source="customer_id_select" reference="customers" label="customer">
        <AutocompleteInput source="name" />
      </ReferenceInput>
      <SelectInput
        source="latest_status"
        label="Status"
        choices={[
          { id: 'unpaid', name: 'UNPAID' },
          { id: 'paid', name: 'PAID' },
          { id: 'quote', name: 'QUOTE' },
          { id: 'pending', name: 'PENDING' },
          { id: 'success', name: 'SUCCESS' },
          { id: 'failed', name: 'FAILED' },
          { id: 'canceled', name: 'CANCELED' },
        ]}
        alwaysOn
      />
      <SelectInput
        source="payment_channel"
        label="Payment gateway"
        choices={[
          { id: 'NOPAY', name: 'NOPAY' },
          { id: 'SHOPEEPAY', name: 'SHOPEEPAY' },
          { id: 'BOOST', name: 'BOOST' },
          { id: 'FPX', name: 'FPX' },
          { id: 'TNG', name: 'TNG' },
          { id: 'SEVENTW', name: '7-11' },
          { id: 'GRABPAY', name: 'GRABPAY' },
          { id: 'SG_ENETS', name: 'eNETS Debit' },
        ]}
        alwaysOn
      />
      <TextInput source="payment_id" label="Payment Id" />
    </Filter>
  )
}

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props
  const { resource, displayedFilters, filterValues, basePath, showFilter } = useListContext()
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ExportButton filter={filterValues} resourceType="TRANSACTION" />
    </TopToolbar>
  )
}

const FilterByThisCustomerBtn = ({ id }) => {
  const history = useHistory()

  const onClickHandler = (e) => {
    e.stopPropagation()
    history.push({
      pathname: '/transactions',
      search: encodeURI(`?filter={"customer_id":"${id}"}`),
    })
  }
  return (
    <Tooltip title="Filter by This customer" placement="top">
      <IconButton size="small" onClick={(e) => onClickHandler(e)}>
        <FilterListIcon style={styles.smallIcon} />
      </IconButton>
    </Tooltip>
  )
}

const RedirectToLoyaltyRewardBtn = ({ customerData }) => {
  const history = useHistory()
  const updateCustId = useCustomerId((state) => state?.updateCustData)
  const onClickHandler = (e, customerData) => {
    e.stopPropagation()
    updateCustId({
      id: customerData?.id,
      name: customerData?.name,
    })
    history.push({
      pathname: '/wallets',
    })
  }
  return (
    <Tooltip title="Filter by This customer On Loyalty Rewards Logs" placement="top">
      <IconButton size="small" onClick={(e) => onClickHandler(e, customerData)}>
        <MonetizationOnIcon style={styles.smallIcon} />
      </IconButton>
    </Tooltip>
  )
}

class Transactions extends React.Component {
  render() {
    const { push, classes, ...props } = this.props
    return (
      <Fragment>
        <List
          {...props}
          filters={<TransactionsFilter />}
          exporter={exporter}
          actions={<ListActions />}
          filterDefaultValues={{
            dateGte: moment().startOf('month').format('YYYY/MM/DD'),
            dateLte: moment().endOf('month').format('YYYY/MM/DD'),
          }}
        >
          <Grid item xs={12}>
            <Datagrid rowStyle={rowStyleByStatus} rowClick="show">
              <FunctionField
                label="Created"
                render={(record) => {
                  console.log(739123312412, 'record', record)
                  const date = moment(record?.created_at).format('MM/DD/YYYY')
                  const time = moment(record?.created_at).format('HH:mm:ss')
                  return (
                    <>
                      <label>{date}</label>
                      <br />
                      <label>{time}</label>
                    </>
                  )
                }}
              />
              <TextField source="order_id" label="Order ID" />
              <FunctionField
                label="Buyer"
                render={(record) =>
                  record.user_id ? (
                    <>
                      <label>{record?.users?.name}</label>
                      <br />
                      <label>
                        <b>{record?.organizations ? record?.organizations?.name : ''}</b>
                      </label>
                      <br />
                      <label>
                        <b>[{record?.channel}]</b>
                      </label>
                    </>
                  ) : (
                    <>
                      <label>{record?.customers?.name}</label>
                      <br />
                      <label>
                        <b>{record?.organizations ? record?.organizations?.name : ''}</b>
                      </label>
                      <br />
                      <label>
                        <b>[{record?.channel}]</b>
                      </label>
                    </>
                  )
                }
              />
              <FunctionField
                label="Product"
                render={(record) => (
                  <label
                    style={{
                      maxWidth: '75px',
                      width: '75px',
                      display: 'inline-block',
                    }}
                  >
                    {record?.products?.description}
                  </label>
                )}
              />
              <TextField source="target" />
              <FunctionField
                label="Point"
                render={(record) => {
                  if (record.pg_data && record.pg_data.payment_data) {
                    return (
                      <div>
                        <label>{record.payment_id}</label>
                        <br />
                        {['SPAY', 'SENANGPAY'].includes(record.pg_data.pg_channel) ? (
                          <label className={classes.tranID}>
                            {record.pg_data.payment_data.payment_info?.transaction_reference ||
                              record.pg_data.payment_data.transaction_id}
                          </label>
                        ) : (
                          <label className={classes.tranID}>
                            {record.pg_data.payment_data.tranID}
                          </label>
                        )}
                        <br />
                        <b>{paymentChannelDesc[record.pg_channel]}</b>
                      </div>
                    )
                  }
                  return (
                    <div>
                      <label>{record.payment_id}</label>
                      <br />
                      <b>{paymentChannelDesc[record.pg_channel]}</b>
                    </div>
                  )
                }}
              />
              {/* <TextField source="sell_price" label="Price" /> */}
              <FunctionField
                label="Price"
                textAlign="right"
                render={(record) => (
                  <label>
                    <p
                      style={{
                        textAlign: 'right',
                      }}
                    >
                      {record.sell_price.toFixed(2)}
                    </p>
                  </label>
                )}
              />
              <FunctionField
                label="Cost"
                render={(record) => {
                  let record_cost = ''
                  if (record.discount_type !== null) {
                    record_cost = <b>[{record.discount_type.join(', ')}]</b>
                  } else {
                    if (record.poin > 0) {
                      if (record.promotion_code === null) {
                        record_cost = <b>[VOUCHER]</b>
                      } else {
                        record_cost = <b>[VOUCHER, {record.promotion_code}]</b>
                      }
                    } else {
                      if (record.promotion_code !== null) {
                        record_cost = <b>[{record.promotion_code}]</b>
                      }
                    }
                  }
                  return (
                    <div>
                      <label>Cost: {record.cost}</label>
                      <br />
                      <label>
                        Disc.: {record.discount}&nbsp;{record_cost}
                      </label>
                      <br />
                      <label>Fee: {record.pg_fee}</label>
                    </div>
                  )
                }}
              />
              <FunctionField
                label="Profit"
                textAlign="right"
                render={(record) => (
                  <label className={record.profit < 0 ? classes.lossProfit : null}>
                    <p
                      style={{
                        textAlign: 'right',
                      }}
                    >
                      {record.profit.toFixed(2)}
                    </p>
                  </label>
                )}
              />
              <FunctionField
                label="Status"
                render={(record) => <label>{record.latest_status.toUpperCase()}</label>}
              />
              <FunctionField
                label="Action"
                render={(record) => {
                  console.log(7391273123213, record)
                  return (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Tooltip title="See Details" placement="top">
                        <IconButton
                          size="small"
                          onClick={() => this.handleOpenTransactionDetails(record?.id)}
                        >
                          <VisibilityIcon style={styles.smallIcon} />
                        </IconButton>
                      </Tooltip>
                      <FilterByThisCustomerBtn id={record?.customers?.id} />
                      <RedirectToLoyaltyRewardBtn customerData={record?.customers} />
                    </div>
                  )
                }}
              />
            </Datagrid>
          </Grid>
        </List>
        <Route path="/transactions/:id/show">
          {({ match }) => {
            const isMatch = match && match.params
            return (
              <Drawer open={!!isMatch} anchor="right" onClose={this.handleClose}>
                {isMatch ? (
                  <TransactionDetail
                    className={classes.drawerContent}
                    id={isMatch ? match.params.id : null}
                    onCancel={this.handleClose}
                    {...props}
                  />
                ) : (
                  <div className={classes.drawerContent} />
                )}
              </Drawer>
            )
          }}
        </Route>
      </Fragment>
    )
  }

  handleClose = () => {
    this.props.push('/transactions')
  }

  handleOpenTransactionDetails = (id) => {
    this.props.push(`/transactions/${id}/show`)
  }
}

export default compose(connect(undefined, { push }), withStyles(styles))(Transactions)
