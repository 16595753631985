import React, { useState } from 'react'
import {
  useQuery,
  Pagination,
  Loading,
  Show,
  Button,
  TabbedShowLayout,
  Tab,
  required,
  SaveButton,
  TextInput,
  useCreate,
  useNotify,
  FormWithRedirect,
  SelectInput,
  useRedirect,
  TextField,
  BooleanField,
  FunctionField,
  useMutation,
  useRefresh,
  useAuthProvider,
} from 'react-admin'
import { TableRow, TableHead, TableCell, TableBody, Table, Grid, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ReactJson from 'react-json-view'
import moment from 'moment'
import IconCancel from '@material-ui/icons/Cancel'
import SyncIcon from '@material-ui/icons/Sync'
import MaterialButton from '@material-ui/core/Button'
import { GridShowLayout, RaGrid } from 'ra-compact-ui'

import EditStore from './EditStore'
import {
  invokeApiEmitEventTransactionStatus,
  fetchInfoForInvokeApiEmitEventTransactionStatus,
} from './../requests'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexGrow: 1,
    height: 350,
  },
  input: {
    marginBottom: '-20px',
  },
  text: {
    margin: 0,
  },
  lossProfit: {
    color: 'red',
  },
  detailsBox: {
    borderBottom: 'solid thin rgba(0, 0, 0, 0.12)',
    marginBottom: '20px',
  },
  groupedPayment: {
    marginBottom: '-10px',
    marginTop: '-5px',
  },
})

const channelMapping = {
  SG_ENETS: 'eNETS Debit',
  SG_SOCASH_CASH: 'Socash',
  SEVENTW: '7-11',
  HILIFETW: 'Hi-Life',
  FAMY: 'Family Mart',
  BIGC: 'Big C',
  TESCO: 'TESCO Lotus',
  BIMB: 'FPX B2C Bank Islam',
  MB2U: 'FPX B2C Maybank',
  PBB: 'FPX B2C Public Bank',
  CIMBCLICKS: 'FPX B2C CIMBClicks',
  AMOL: 'FPX B2C AmOnline',
  HLB: 'FPX B2C Hong Leong',
  RHB: 'FPX B2C RHB Bank',
  FPX_OCBC: 'FPX B2C OCBC',
  FPX_SCB: 'FPX B2C Standard Chartered',
  FPX_ABB: 'FPX B2C Affin Bank',
  BANKRAKYAT: 'FPX B2C Bank Rakyat',
  BANKMUAMALAT: 'FPX B2C Bank Muamalat',
  FPX_KFH: 'FPX B2C Kuwait Finance House',
  FPX_BSN: 'FPX B2C Bank Simpanan Nasional',
  FPX_ABMB: 'FPX B2C Alliance Bank ',
  FPX_UOB: 'FPX B2C United Overseas Bank',
  FPX_HSBC: 'FPX B2C HSBC Bank',
  TNG: 'Touch N’ Go E-WALLET',
  GRABPAY: 'GrabPay',
  BOOST: 'BOOST',
  IPAY88_BOOST: 'BOOST',
  IPAY88_BIMB: 'FPX B2C Bank Islam',
  IPAY88_MB2U: 'FPX B2C Maybank',
  IPAY88_PBOL: 'FPX B2C Public Bank',
  IPAY88_CIMBCLICK: 'FPX B2C CIMBClicks',
  IPAY88_AMOL: 'FPX B2C AmOnline',
  IPAY88_HLOL: 'FPX B2C Hong Leong',
  IPAY88_RHBO: 'FPX B2C RHB Bank',
  IPAY88_OCBC: 'FPX B2C OCBC',
  IPAY88_SCB: 'FPX B2C Standard Chartered',
  IPAY88_AFOL: 'FPX B2C Affin Bank',
  IPAY88_BRRB: 'FPX B2C Bank Rakyat',
  IPAY88_BMMB: 'FPX B2C Bank Muamalat',
  IPAY88_KFH: 'FPX B2C Kuwait Finance House',
  IPAY88_BSNO: 'FPX B2C Bank Simpanan Nasional',
  IPAY88_ALOL: 'FPX B2C Alliance Bank',
  IPAY88_UOB: 'FPX B2C United Overseas Bank',
  IPAY88_HSBC: 'FPX B2C HSBC Bank',
}

const TableList = ({ record }) => {
  const classes = useStyles()
  const [page, setPage] = useState(1)
  const perPage = 10

  const { data, total, loading, error } = useQuery({
    type: 'getList',
    resource: 'transaction_histories',
    payload: {
      pagination: { page, perPage },
      sort: { field: 'created_at', order: 'DESC' },
      filter: { transaction_id: record.id },
    },
  })

  if (loading) {
    return <Loading />
  }
  if (error) {
    return <p>ERROR: {error}</p>
  }

  const SyncButton = () => {
    const redirect = useRedirect()
    const notify = useNotify()
    const refresh = useRefresh()
    const [approve, { loading }] = useMutation(
      {
        type: 'update',
        resource: 'transactions_sync',
        payload: {
          id: record.id,
          data: { latest_status: record.latest_status },
        },
      },
      {
        onSuccess: ({ data }) => {
          redirect(`/transactions/${record.id}/show`)
          notify('The data has been synchronized')
          refresh()
        },
      }
    )
    return (
      <MaterialButton
        startIcon={<SyncIcon />}
        color="primary"
        variant="contained"
        size="small"
        onClick={approve}
        disabled={loading}
      >
        Sync
      </MaterialButton>
    )
  }

  const SyncRPGButton = () => {
    const authP = useAuthProvider()

    const handleOnClick = async () => {
      const [, { supplierRef, apiUsername, apiPassword }] =
        await fetchInfoForInvokeApiEmitEventTransactionStatus({
          authUserId: authP.id(),
          orderIdTransaction: record.order_id,
        })

      const [, result] = await invokeApiEmitEventTransactionStatus({
        supplierRef,
        apiUsername,
        apiPassword,
      })

      const isSuccessEmitEvent = result.data.toUpperCase() === 'OK'
      if (isSuccessEmitEvent) {
        window.alert('Success Sync RPG')
        window.location.reload()
      } else {
        window.alert('Failed Sync RPG')
      }
    }

    return (
      <MaterialButton
        startIcon={<SyncIcon />}
        color="primary"
        variant="contained"
        size="small"
        onClick={handleOnClick}
        // disabled={loading}
      >
        Sync RPG
      </MaterialButton>
    )
  }

  return (
    <>
      <Box display="flex" justifyContent="flex-end" pt={2} style={{ gap: '0.5rem' }}>
        <SyncButton />
        <SyncRPGButton />
      </Box>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Remark</TableCell>
            <TableCell>Data</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((data) => (
            <TableRow key={data.id}>
              <TableCell>{moment(data.created_at).format('DD/MM/YYYY, hh:mm:ss A')}</TableCell>
              <TableCell>{data.status.toUpperCase()}</TableCell>
              <TableCell>{data.remark}</TableCell>
              <TableCell>
                <ReactJson
                  src={data.data || []}
                  shouldCollapse={() => {}}
                  enableClipboard={false}
                  displayDataTypes={false}
                  displayObjectSize={false}
                  name={false}
                />
              </TableCell>
            </TableRow>
          ))}
          <TableRow></TableRow>
        </TableBody>
      </Table>
      <Pagination
        page={page}
        perPage={perPage}
        setPage={setPage}
        total={total}
        rowsPerPageOptions={[10]}
      />
    </>
  )
}

const Override = ({ onCancel, record }) => {
  const [create, { loading }] = useCreate()
  const notify = useNotify()
  const classes = useStyles()
  const redirect = useRedirect()
  const refresh = useRefresh()

  const handleSubmit = async (values) => {
    create('override', values, {
      onSuccess: () => {
        notify('data has been override', { type: 'success' })
        redirect('/transactions')
        refresh()
      },
      onFailure: (error) => {
        notify(error.message, { type: 'error' })
      },
    })
  }
  return (
    <div
      style={{
        padding: '2px',
        marginBottom: '10px',
      }}
    >
      <FormWithRedirect
        resource="override"
        save={handleSubmit}
        render={({ handleSubmitWithRedirect, pristine, saving }) => (
          <Grid container>
            <Grid item xs={12}>
              <TextInput
                source="id"
                fullWidth
                validate={required()}
                defaultValue={record.id}
                disabled
                className={classes.input}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput source="token" fullWidth className={classes.input} />
            </Grid>
            <Grid item xs={12}>
              <TextInput source="newTarget" label="target" fullWidth className={classes.input} />
            </Grid>
            <Grid item xs={12}>
              <TextInput source="reason" fullWidth className={classes.input} />
            </Grid>
            <Grid item xs={12}>
              <SelectInput
                source="newStatus"
                fullWidth
                validate={required()}
                choices={[
                  { id: 'cancel', name: 'Cancel' },
                  { id: 'paid', name: 'Paid' },
                  { id: 'paid_execute_only', name: 'Paid execute only' },
                  { id: 'failed_silent', name: 'Failed Silent' },
                  { id: 'failed', name: 'Failed' },
                  { id: 'success_silent', name: 'Success Silent' },
                  { id: 'success', name: 'Success' },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <SaveButton
                handleSubmitWithRedirect={handleSubmitWithRedirect}
                pristine={pristine}
                saving={saving}
                disabled={loading}
                label="Override"
              />
              <Button label="ra.action.cancel" onClick={onCancel} disabled={loading}>
                <IconCancel />
              </Button>
            </Grid>
          </Grid>
        )}
      />
    </div>
  )
}

const Snapshot = (props) => {
  return (
    <>
      <label>{props?.record?.reference_snapshots?.snapshot?.product_details?.name}</label>
      <ReactJson
        src={props?.record?.reference_snapshots?.snapshot}
        shouldCollapse={() => {}}
        enableClipboard={false}
        displayDataTypes={false}
        displayObjectSize={false}
        name={false}
      />
    </>
  )
}

const Customer = (props) => {
  const customer = {
    created_at: moment(props?.record?.customer_id_data?.created_at).format('MM/DD/YYYY'),
    msisdn: props?.record?.user_id_data?.msisdn || '',
  }

  return props?.record?.user_id ? (
    <>
      <label>{props?.record?.users?.name}</label>
      <label>
        <b> [{props?.record?.channel}]</b>
      </label>
    </>
  ) : (
    <>
      <label>{props?.record?.customers?.name}</label>
      <label>
        <b> [{props?.record?.customers?.channel}]</b>
      </label>
      <ReactJson
        src={customer}
        collapsed={false}
        enableClipboard={false}
        displayDataTypes={false}
        displayObjectSize={false}
        name={false}
      />
    </>
  )
}

export const TransactionDetail = ({ onCancel, ...props }) => {
  const classes = useStyles()
  return (
    <Show {...props} title=" ">
      <TabbedShowLayout>
        <Tab label="Detail">
          <GridShowLayout>
            <RaGrid className={classes.detailsBox} container spacing={3}>
              <RaGrid item xs={3}>
                <b className={classes.text}>Transaction ID</b>
              </RaGrid>
              <RaGrid item xs={9}>
                <TextField source="order_id" addLabel={false} />
              </RaGrid>
            </RaGrid>
            <RaGrid className={classes.detailsBox} container spacing={3}>
              <RaGrid item xs={3}>
                <b className={classes.text}>Status</b>
              </RaGrid>
              <RaGrid item xs={9}>
                <FunctionField
                  addLabel={false}
                  render={(record) => <label>{record.latest_status.toUpperCase()}</label>}
                />
              </RaGrid>
            </RaGrid>
            <RaGrid className={classes.detailsBox} container spacing={3}>
              <RaGrid item xs={3}>
                <b className={classes.text}>Allow Alter</b>
              </RaGrid>
              <RaGrid item xs={9}>
                <BooleanField source="allow_alter" addLabel={false} />
              </RaGrid>
            </RaGrid>
            <RaGrid className={classes.detailsBox} container spacing={3}>
              <RaGrid item xs={3}>
                <b className={classes.text}>Target</b>
              </RaGrid>
              <RaGrid item xs={9}>
                <TextField source="target" addLabel={false} />
              </RaGrid>
            </RaGrid>
            <RaGrid className={classes.detailsBox} container spacing={3}>
              <RaGrid item xs={3}>
                <b className={classes.text}>Sell Price</b>
              </RaGrid>
              <RaGrid item xs={9}>
                <FunctionField
                  source="sell_price"
                  render={(record) => <label>{`${record.currency} ${record.sell_price}`}</label>}
                  addLabel={false}
                />
              </RaGrid>
            </RaGrid>
            <RaGrid className={classes.detailsBox} container spacing={3}>
              <RaGrid item xs={3}>
                <b className={classes.text}>Payment Amount</b>
              </RaGrid>
              <RaGrid item xs={9}>
                <FunctionField
                  render={(record) => {
                    return <label>{record?.metadata?.payment_amount}</label>
                  }}
                  addLabel={false}
                />
              </RaGrid>
            </RaGrid>
            <RaGrid className={classes.detailsBox} container spacing={3}>
              <RaGrid item xs={3}>
                <b className={classes.text}>Paid by Credit Refund</b>
              </RaGrid>
              <RaGrid item xs={9}>
                <FunctionField
                  render={(record) => {
                    return <label>{record?.metadata?.paid_by_credit_refund}</label>
                  }}
                  addLabel={false}
                />
              </RaGrid>
            </RaGrid>
            <RaGrid className={classes.detailsBox} container spacing={3}>
              <RaGrid item xs={3}>
                <b className={classes.text}>Discount by Credit Gimmick</b>
              </RaGrid>
              <RaGrid item xs={9}>
                <FunctionField
                  render={(record) => {
                    return <label>{record?.metadata?.discounted_by_credit_gimmick}</label>
                  }}
                  addLabel={false}
                />
              </RaGrid>
            </RaGrid>
            <RaGrid className={classes.detailsBox} container spacing={3}>
              <RaGrid item xs={3}>
                <b className={classes.text}>Cost</b>
              </RaGrid>
              <RaGrid item xs={9}>
                <FunctionField
                  source="cost"
                  render={(record) => <label>{`${record.currency} ${record.cost}`}</label>}
                  addLabel={false}
                />
              </RaGrid>
            </RaGrid>
            <RaGrid className={classes.detailsBox} container spacing={3}>
              <RaGrid item xs={3}>
                <b className={classes.text}>Fee</b>
              </RaGrid>
              <RaGrid item xs={9}>
                <FunctionField
                  source="pg_fee"
                  render={(record) => <label>{`${record.currency} ${record.pg_fee}`}</label>}
                  addLabel={false}
                />
              </RaGrid>
            </RaGrid>
            <RaGrid className={classes.detailsBox} container spacing={3}>
              <RaGrid item xs={3}>
                <b className={classes.text}>Discount</b>
              </RaGrid>
              <RaGrid item xs={9}>
                <FunctionField
                  render={(record) => {
                    let record_cost = ''

                    function showPromoCodenVoucher(record) {
                      if (record.poin > 0) {
                        if (record.promotion_code === null) {
                          return '[VOUCHER]'
                        } else {
                          return `[VOUCHER, ${record.promotion_code}]`
                        }
                      } else {
                        if (record.promotion_code !== null) {
                          return `[${record.promotion_code}]`
                        }
                      }
                    }

                    if (record.discount) {
                      if (record.discount_type !== null) {
                        record_cost = `[${record.discount_type.join(', ')}]`
                      } else {
                        showPromoCodenVoucher()
                      }
                      return (
                        <label>
                          {record.currency}&nbsp;{record.discount}&nbsp;<b>{record_cost}</b>
                        </label>
                      )
                    }

                    return '-'
                  }}
                  addLabel={false}
                />
              </RaGrid>
            </RaGrid>
            <RaGrid className={classes.detailsBox} container spacing={3}>
              <RaGrid item xs={3}>
                <b className={classes.text}>Profit</b>
              </RaGrid>
              <RaGrid item xs={9}>
                <FunctionField
                  source="profit"
                  render={(record) => (
                    <label className={record.profit < 0 ? classes.lossProfit : null}>
                      {`${record.currency} ${record.profit}`}
                    </label>
                  )}
                  addLabel={false}
                />
              </RaGrid>
            </RaGrid>
            <RaGrid className={classes.detailsBox} container spacing={3}>
              <RaGrid item xs={3}>
                <b className={classes.text}>Payment Data</b>
              </RaGrid>
              <RaGrid item xs={9}>
                <FunctionField
                  addLabel={false}
                  render={(record) => {
                    if (record.payment_id) {
                      return (
                        <label>
                          <div className={classes.groupedPayment}>
                            <b>{record.payment_id}</b>
                            {record.latest_status !== 'unpaid' &&
                            record.pg_channel === 'SEVENTW' ? (
                              <EditStore {...record} />
                            ) : (
                              ''
                            )}
                          </div>
                          {record.store_data && (
                            <>
                              <br />
                              {record.store_data.store_address}
                              <br />
                              <ReactJson
                                src={record.store_data || []}
                                shouldCollapse={() => {}}
                                enableClipboard={false}
                                displayDataTypes={false}
                                displayObjectSize={false}
                                name={false}
                              />
                            </>
                          )}
                          {record.pg_channel !== 'SEVENTW' && (
                            <>
                              <br />
                              {channelMapping[record.pg_channel]}
                            </>
                          )}
                        </label>
                      )
                    }

                    return '-'
                  }}
                />
              </RaGrid>
            </RaGrid>
            <RaGrid className={classes.detailsBox} container spacing={3}>
              <RaGrid item xs={3}>
                <b className={classes.text}>Product(Snapshot)</b>
              </RaGrid>
              <RaGrid item xs={9}>
                <Snapshot source="product_snapshot_id" addLabel={false} />
              </RaGrid>
            </RaGrid>
            <RaGrid className={classes.detailsBox} container spacing={3}>
              <RaGrid item xs={3}>
                <b className={classes.text}>Supplier Reference</b>
              </RaGrid>
              <RaGrid item xs={9}>
                <TextField source="supplier_reference_id" addLabel={false} />
              </RaGrid>
            </RaGrid>
            <RaGrid className={classes.detailsBox} container spacing={3}>
              <RaGrid item xs={3}>
                <b className={classes.text}>Customer</b>
              </RaGrid>
              <RaGrid item xs={9}>
                <Customer source="customer_id" addLabel={false} />
              </RaGrid>
            </RaGrid>
          </GridShowLayout>
        </Tab>
        <Tab label="History">
          <TableList />
        </Tab>
        <Tab label="Override">
          <Override onCancel={onCancel} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
